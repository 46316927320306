import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import necessary Firebase storage functions
import { db, storage } from './firebase';

const updateProfile = async (userId, profileData) => {
  const userRef = doc(db, 'users', userId);
  await setDoc(userRef, profileData, { merge: true });
};

const uploadProfilePicture = async (userId, file) => {
  // Create a reference to the user's profile picture in Firebase Storage
  const storageRef = ref(storage, `profilePictures/${userId}/${file.name}`);

  // Upload the file to Firebase Storage
  await uploadBytes(storageRef, file);

  // Get the download URL of the uploaded file
  const downloadURL = await getDownloadURL(storageRef);

  return downloadURL;
};

export { updateProfile, uploadProfilePicture };