import React from 'react';
import './info.css';
import { Link } from 'react-router-dom';

const InfoPage = () =>
<div className="product-list-container">
<h1>Welcome to Memoir Database!</h1>
<h2>Making Memories last a Lifetime!</h2>
<p>Memoir Codes allow you to preserve a memory, whether it be a special occasion, event, a dear loved one
    or a missed pet, the choice is 100% yours!
    After purchasing a Memoir Code, users can register to our MemoirDatabase and upload the documents they
    wish to add to their Memoir Page. Every purchase the user will receive a Unique Memoir Code that will guide
    you to your memory in just a quick scan from any mobile device!
    Upon order, customers will receive: <br></br><br></br>

    <b>1x Memoir Page dedicated to the memory of your choosing<br></br>
    1x Memoir Code Printout for safekeeping<br></br>
    2x Handmade Keychains displaying your Memoir Code<br></br>
    3x Memoir Code Stickers</b><br></br><br></br>

    <i>What to expect after purchasing: After purchasing and creating your account at MemoirDatabase.Life
        you'll receive your digital copy of your Memoir Code. Your Digital Memoir Code is uploaded to your 
        MemoirDatabase account with 2 - 3 business days. Printout, Stickers and Keychains will arrive by mail 
        to the address you specify in your MemoirDatabase account within 2 - 3 weeks!
    </i><br></br><br></br>

    Not sure what to include? Your Memoir Page can hold a series of pictures, videos, music, text, all Memoir pages
    come with a Guestbook allowing visitors to leave a message (unless you wish to remove the Guestbook). Our Memoir
    Code stickers can be placed at memorial sites, weddings, graduations, funerals, conecerts, use it to hold a memory
    or maybe a promotion. The choices are endless and completely up to you! 
    
</p>
<h2><b><i>$50 CAD</i></b></h2>
 <br></br>
 <h2><b>Purchases can be made through the app or visiting Www.MemoirCodes.Life</b></h2>

 <Link to="/" className="button">Back to Main Page</Link>
  </div>

export default InfoPage;