import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './ForgotPassword.css'; // You may need to create this file for styling

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();

      // Send password reset email
      await sendPasswordResetEmail(auth, email);

      // Notify the user that the reset email has been sent
      toast.success('Password reset email sent! Check your inbox.');

      // Redirect to login page
      navigate('/login');
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      // Notify the user of the error
      toast.error(`Password reset failed: ${error.message}`);
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Forgot Password</h2>
      <form onSubmit={handleResetPassword}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <button type="submit">Reset Password</button>
      </form>

      <p>
        Remember your password? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
};

export default ForgotPassword;