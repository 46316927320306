import React, { useState, useEffect } from 'react';
import { updateProfile } from './profileutils';
import { auth } from './firebase';
import { getFirestore, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Profile.css';
import SignOutButton from './SignOutButton';

const Profile = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          username: '',
          location: '',
          website: '',
          bio: '',
          memoirCode: '',
        };
  });

  const [formValues, setFormValues] = useState({
    username: '',
    location: '',
    website: '',
    bio: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;
    if (!user) {
      console.error('User not authenticated.');
      // Redirect to login if the user is not logged in
      navigate('/login');
      return;
    }

    const userId = user.uid;

    try {
      await updateProfile(userId, formValues);
      setUserInfo(formValues);
      localStorage.setItem('userData', JSON.stringify(formValues));
    } catch (error) {
      console.error('Error updating user information:', error.message);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = auth.currentUser;

      if (!user) {
        // Redirect to login if the user is not logged in
        navigate('/login');
        return;
      }

      const db = getFirestore();
      const userDocRef = doc(db, 'users', user.uid);

      try {
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setUserInfo(userData);
        }
      } catch (error) {
        console.error('Error fetching user information:', error.message);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  // Navigate to /dashboard
  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  // Function to handle account deletion
  const handleDeleteAccount = async () => {
    // Show confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to delete your account?');
    if (!isConfirmed) {
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      console.error('User not authenticated.');
      return;
    }

    const userId = user.uid;

    try {
      // Delete user data from Firestore
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      await deleteDoc(userDocRef);

      // Delete user from Firebase Authentication
      await auth.currentUser.delete();

      // Show success notification
      toast.success('Account deleted successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Redirect to the login page after successful account deletion
      navigate('/login');
    } catch (error) {
      // Show error notification
      toast.error(`Error deleting user account: ${error.message}`, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Error deleting user account:', error.message);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-background">
        <div className="profile-display-container">
          <h2>User Information</h2>
          <p>
            <strong>Username:</strong> {userInfo.username}
          </p>
          <p>
            <strong>Address:</strong> {userInfo.location}
          </p>
          <p>
            <strong>Website:</strong> {userInfo.website}
          </p>
          <p>
            <strong>Bio:</strong> {userInfo.bio}
          </p>
          <p>
            <strong>MemoirCodes:</strong>
            {Array.isArray(userInfo.memoirCode) ? (
              userInfo.memoirCode.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Memoir Code ${index + 1}`}
                  style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }}
                />
              ))
            ) : (
              userInfo.memoirCode
            )}
          </p>
          <p>
            <strong>User:</strong> {auth.currentUser ? auth.currentUser.email : 'Not logged in'}
          </p>
        </div>

        <div className="profile-update-container">
          <h2>Edit Profile</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formValues.username}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, username: e.target.value }))}
            />

            <label htmlFor="location">Address:</label>
            <input
              type="text"
              id="location"
              name="location"
              value={formValues.location}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, location: e.target.value }))}
            />

            <label htmlFor="website">Website:</label>
            <input
              type="text"
              id="website"
              name="website"
              value={formValues.website}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, website: e.target.value }))}
            />

            <label htmlFor="bio">Bio:</label>
            <textarea
              id="bio"
              name="bio"
              value={formValues.bio}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, bio: e.target.value }))}
            />

            <button type="submit">Save Changes</button>
          </form>

          <SignOutButton />
        </div>

        {/* Navigate to /dashboard Button */}
        <div className="send-documents-container">
          <button onClick={handleNavigateToDashboard}>Go to Dashboard</button>
        </div>
      </div>

      {/* Delete Account Button with Confirmation Dialog */}
      <div className="delete-account-container">
        <button onClick={handleDeleteAccount}>Delete My Account</button>
      </div>

      {/* Toastify Notification Container */}
      <ToastContainer />
    </div>
  );
};

export default Profile;