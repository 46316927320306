import React from 'react';
import Categories from '../Categories';


const Database = () =>
    <div className="App">   
    <h1><b>Memoir</b></h1>

    <main>
      <Categories
        name="Database Entry"
        description="0000000000"
        image="/assets/Database/DatabaseExample.png"
      />
       
       
      {/* Add more products as needed */}
    </main>
  </div>

export default Database;