import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Set persistence to LOCAL for permanent persistence
      const auth = getAuth();
      await setPersistence(auth, browserLocalPersistence);

      // Login with Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);

      // Notify user of successful login
      toast.success('Login successful!');

      // Redirect to a different page (e.g., profile page)
      navigate('/profile');

    } catch (error) {
      console.error('Error logging in:', error.message);
      // Notify user of login error
      toast.error(`Login failed: ${error.message}`);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit">Login</button><br></br>
      </form>
      <br></br>
      <button onClick={() => window.location.href = '/'}>Back to HomePage</button>

      <p>
        <Link to="/forgot-password">Forgot your password?</Link>
      </p>

      <p>
        Don't have an account? <Link to="/signup">Sign up here</Link>
      </p>
    </div>
  );
};

export default Login;