import React from 'react';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignOutButton = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        // Show success toast notification
        toast.success('Successfully logged out!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Auto-close the notification after 3000 milliseconds (3 seconds)
        });

        // Redirect the user to the landing page after sign-out
        navigate('/');
      })
      .catch((error) => {
        // Show error toast notification
        toast.error(`Error signing out: ${error.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000, // Auto-close the notification after 5000 milliseconds (5 seconds)
        });

        console.error('Error signing out:', error.message);
      });
  };

  return (
    <button className="sign-out-button" onClick={handleSignOut}>
      Sign Out
    </button>
  );
};

export default SignOutButton;
