import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database'; // Correct import for Realtime Database

const firebaseConfig = {
  apiKey: "AIzaSyD8sy9sKR1P8gn9zGJDYlnIM0kUeNmrYcA",
  authDomain: "memoircode.firebaseapp.com",
  projectId: "memoircode",
  storageBucket: "memoircode.appspot.com",
  messagingSenderId: "335848966624",
  appId: "1:335848966624:web:e2f435a804d80feb5b0385",
  measurementId: "G-LLX6RVFFSD"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const database = getDatabase(firebaseApp);

// Set max operation retry time to 5 minutes (300,000 milliseconds)

export { auth, db, storage, database };