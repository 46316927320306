import React, { useState, useEffect } from 'react';
import { storage, db as firestore } from './firebase';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import './UserDashboard.css';

const UserDashboard = () => {
  const [user, setUser] = useState(null);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [music, setMusic] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [musicFiles, setMusicFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [paragraph1, setParagraph1] = useState('');
  const [paragraph2, setParagraph2] = useState('');
  const [paragraph3, setParagraph3] = useState('');
  const [paragraph4, setParagraph4] = useState('');
  const [paragraph5, setParagraph5] = useState('');
  const [paragraph6, setParagraph6] = useState('');
  const [paragraph7, setParagraph7] = useState('');
  const [paragraph8, setParagraph8] = useState('');
  const [paragraph9, setParagraph9] = useState('');
  const [paragraph10, setParagraph10] = useState('');
  const [guestbookEntries, setGuestbookEntries] = useState([]);

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        fetchUserUploads(user.uid);
        fetchUserDetails(user.uid);
        fetchUserGuestbookEntries(user.uid); // Added this line
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const fetchUserUploads = async (userId) => {
    try {
      const imageRef = ref(storage, `images/${userId}`);
      const imageFilesList = await listAll(imageRef);
      setImageFiles(imageFilesList.items);

      const videoRef = ref(storage, `videos/${userId}`);
      const videoFilesList = await listAll(videoRef);
      setVideoFiles(videoFilesList.items);

      const musicRef = ref(storage, `musics/${userId}`);
      const musicFilesList = await listAll(musicRef);
      setMusicFiles(musicFilesList.items);
    } catch (error) {
      console.error('Error fetching user uploads:', error.message);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setTitle(userData.title || '');
        setParagraph1(userData.paragraph1 || '');
        setParagraph2(userData.paragraph2 || '');
        setParagraph3(userData.paragraph3 || '');
        setParagraph4(userData.paragraph4 || '');
        setParagraph5(userData.paragraph5 || '');
        setParagraph6(userData.paragraph6 || '');
        setParagraph7(userData.paragraph7 || '');
        setParagraph8(userData.paragraph8 || '');
        setParagraph9(userData.paragraph9 || '');
        setParagraph10(userData.paragraph10 || '');
        setGuestbookEntries(userData.guestbook || []);
      }
    } catch (error) {
      console.error('Error fetching user details:', error.message);
    }
  };

  const fetchUserGuestbookEntries = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setGuestbookEntries(userData.guestbook || []);
      }
    } catch (error) {
      console.error('Error fetching guestbook entries:', error.message);
    }
  };

  const handleFileUpload = async (file, fileType) => {
    try {
      const authUser = auth.currentUser;

      if (!authUser) {
        console.error('User not authenticated.');
        return;
      }

      const userId = authUser.uid;

      const storageRef = ref(storage, `${fileType}s/${userId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      fetchUserUploads(userId);

      console.log(`${fileType} uploaded:`, fileUrl);

      notifySuccess(`${fileType} uploaded successfully.`);
    } catch (error) {
      console.error(`Error uploading ${fileType}:`, error.message);
      notifyError(`Error uploading ${fileType}: ${error.message}`);
    }
  };

  const handleImageUpload = () => {
    handleFileUpload(image, 'image');
  };

  const handleVideoUpload = () => {
    handleFileUpload(video, 'video');
  };

  const handleMusicUpload = () => {
    handleFileUpload(music, 'music');
  };

  const handleDelete = async (file, fileType) => {
    try {
      await deleteObject(file);
      console.log(`${fileType} deleted successfully.`);
      fetchUserUploads(auth.currentUser.uid);
      notifySuccess(`${fileType} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting ${fileType}:`, error.message);
      notifyError(`Error deleting ${fileType}: ${error.message}`);
    }
  };

  const handleDeleteGuestbookEntry = async (index) => {
    try {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(firestore, 'users', userId);

      await updateDoc(userDocRef, {
        guestbook: guestbookEntries.filter((_, i) => i !== index),
      });

      fetchUserGuestbookEntries(userId);
      notifySuccess('Guestbook entry deleted successfully.');
    } catch (error) {
      console.error('Error deleting guestbook entry:', error.message);
      notifyError(`Error deleting guestbook entry: ${error.message}`);
    }
  };

  const handleUpdateDetails = async () => {
    try {
      const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        title,
        paragraph1,
        paragraph2,
        paragraph3,
        paragraph4,
        paragraph5,
        paragraph6,
        paragraph7,
        paragraph8,
        paragraph9,
        paragraph10,
      });
      console.log('User details updated successfully.');
      notifySuccess('User details updated successfully.');
    } catch (error) {
      console.error('Error updating user details:', error.message);
      notifyError(`Error updating user details: ${error.message}`);
    }
  };

  const handleNavigateToProfile = () => {
    navigate('/profile');
  };

  return (
    <div className="user-dashboard-container">
      <h2>User Dashboard</h2>

      {user && <p>Welcome, {user.email}!</p>}

      {/* Image upload */}
      <input type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
      <button onClick={handleImageUpload}>Upload Image</button>

      {/* Display image files */}
      <div>
        <h3>Image Files</h3>
        <ul>
          {imageFiles.map((file) => (
            <li key={file.name}>
              {file.name}
              <span className="delete-icon" onClick={() => handleDelete(file, 'image')}>
                &#x2715;
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Video upload */}
      <input type="file" accept="video/*" onChange={(e) => setVideo(e.target.files[0])} />
      <button onClick={handleVideoUpload}>Upload Video</button>

      {/* Display video files */}
      <div>
        <h3>Video Files</h3>
        <ul>
          {videoFiles.map((file) => (
            <li key={file.name}>
              {file.name}
              <span className="delete-icon" onClick={() => handleDelete(file, 'video')}>
                &#x2715;
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Music upload */}
      <input type="file" accept="audio/*" onChange={(e) => setMusic(e.target.files[0])} />
      <button onClick={handleMusicUpload}>Upload Music</button>

      {/* Display music files */}
      <div>
        <h3>Music Files</h3>
        <ul>
          {musicFiles.map((file) => (
            <li key={file.name}>
              {file.name}
              <span className="delete-icon" onClick={() => handleDelete(file, 'music')}>
                &#x2715;
              </span>
            </li>
          ))}
        </ul>
      </div>
         {/* Guestbook entries */}
      <div>
        <h3>Guestbook Entries</h3>
        <ul>
          {guestbookEntries.map(({ name, message }, index) => (
            <li key={index}>
              {name}: {message}
              <span className="delete-icon" onClick={() => handleDeleteGuestbookEntry(index)}>
                &#x2715;
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* User details */}
      <div>
        <h3>User Details</h3>
        <div>
          <label>Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 1:</label>
          <textarea value={paragraph1} onChange={(e) => setParagraph1(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 2:</label>
          <textarea value={paragraph2} onChange={(e) => setParagraph2(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 3:</label>
          <textarea value={paragraph3} onChange={(e) => setParagraph3(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 4:</label>
          <textarea value={paragraph4} onChange={(e) => setParagraph4(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 5:</label>
          <textarea value={paragraph5} onChange={(e) => setParagraph5(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 6:</label>
          <textarea value={paragraph6} onChange={(e) => setParagraph6(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 7:</label>
          <textarea value={paragraph7} onChange={(e) => setParagraph7(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 8:</label>
          <textarea value={paragraph8} onChange={(e) => setParagraph8(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 9:</label>
          <textarea value={paragraph9} onChange={(e) => setParagraph9(e.target.value)} />
        </div>
        <div>
          <label>Paragraph 10:</label>
          <textarea value={paragraph10} onChange={(e) => setParagraph10(e.target.value)} />
        </div>
        {/* Update details button */}
        <button onClick={handleUpdateDetails}>Update Details</button>
      </div>

     

      {/* Back to Profile Button */}
      <div>
        <button onClick={handleNavigateToProfile}>Back to Profile</button>
      </div>

      {/* Add ToastContainer from react-toastify */}
      <ToastContainer />
    </div>
  );
};

export default UserDashboard;