import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import landingImage from './NewExample.png'; // Replace with the path to your image

const LandingPage = () => {
  return (
    <div className="profile-container">
      <h2>Welcome to Memoir Database!</h2>
      <p>Please Login or Sign Up to Upload Documents or download your Memoir Code</p>

      {/* Image with a small description */}
      <div className="image-container">
        <img src={landingImage} alt="Memoir" />
        <p className="image-description">Check our Example Page!</p>
      </div>

      <Link to="/login" className="button">Login/SignUp</Link>
      <br />
      <Link to="/profile" className="button">Profile</Link>
      <br />
      <Link to="/info" className="button">Info</Link>
      <br />

      {/* Link to navigate to /privacypolicy */}
      <Link to="/privacypolicy">Privacy Policy</Link>
    </div>
  );
};

export default LandingPage;