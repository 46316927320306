import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
} from 'firebase/firestore';
import ReactPlayer from 'react-player';
import './PublicUserProfile.css'; // Import your CSS file
import './video-gallery.css';
import './Components/image-gallery.css';

const ImageGallery = ({ images }) => {
  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <img key={index} src={image.url} alt={`User Upload ${index + 1}`} />
      ))}
    </div>
  );
};

const VideoGallery = ({ videos }) => {
  return (
    <div className="video-gallery">
      {videos.map((video, index) => (
        <div key={index} className="video-player-container">
          <ReactPlayer className="product-video" url={video.url} controls />
        </div>
      ))}
    </div>
  );
};

const MusicGallery = ({ music }) => {
  return (
    <div className="music-gallery">
      {music.map((track, index) => (
        <div key={index} className="product-info">
          <audio controls>
            <source src={track.url} type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        </div>
      ))}
    </div>
  );
};

const Guestbook = ({ guestbook }) => {
  return (
    <div className="guestbook-container">
      <h3>Guestbook</h3>
      <ul>
        {guestbook.map(({ name, message }, index) => (
          <li key={index}>
            <strong>{name}</strong> {message}
          </li>
        ))}
      </ul>
    </div>
  );
};

const PublicUserProfile = () => {
  const { userId } = useParams();
  const [uploads, setUploads] = useState([]);
  const [title, setTitle] = useState('');
  const [paragraphs, setParagraphs] = useState([]);
  const [guestbook, setGuestbook] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (userId === '0385-3859-3924-2948') {
      navigate('/database/wsNFXoTZjrOi4QrJZrGdo15afeQ2');
      return;
    }

    const fetchUserUploads = async () => {
      try {
        const storage = getStorage();

        const imagesRef = ref(storage, `images/${userId}`);
        const videosRef = ref(storage, `videos/${userId}`);
        const musicRef = ref(storage, `musics/${userId}`);

        const [imagesItems, videosItems, musicItems] = await Promise.all([
          listAll(imagesRef),
          listAll(videosRef),
          listAll(musicRef),
        ]);

        const imagesURLs = await Promise.all(
          imagesItems.items.map(async (item) => {
            return { url: await getDownloadURL(item), type: 'image' };
          })
        );

        const videosURLs = await Promise.all(
          videosItems.items.map(async (item) => {
            return { url: await getDownloadURL(item), type: 'video' };
          })
        );

        const musicURLs = await Promise.all(
          musicItems.items.map(async (item) => {
            return { url: await getDownloadURL(item), type: 'audio' };
          })
        );

        const allUploads = [...musicURLs, ...imagesURLs, ...videosURLs];
        setUploads(allUploads);
      } catch (error) {
        console.error('Error fetching user uploads:', error.message);
      }
    };

    const fetchUserDescription = async () => {
      try {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setTitle(userData.title || '');
          setParagraphs([
            userData.paragraph1 || '',
            userData.paragraph2 || '',
            userData.paragraph3 || '',
            userData.paragraph4 || '',
            userData.paragraph5 || '',
            userData.paragraph6 || '',
            userData.paragraph7 || '',
            userData.paragraph8 || '',
            userData.paragraph9 || '',
            userData.paragraph10 || '',
          ]);
          setGuestbook(userData.guestbook || []);
        }
      } catch (error) {
        console.error('Error fetching user description:', error.message);
      }
    };

    fetchUserUploads();
    fetchUserDescription();
  }, [userId, navigate]);

  const handleAddMessage = async () => {
    try {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);

      await updateDoc(userDocRef, {
        guestbook: arrayUnion({ name, message: newMessage }),
      });

      const updatedUserDoc = await getDoc(userDocRef);

      // If the user document doesn't exist, create it with guestbook field
      if (!updatedUserDoc.exists()) {
        await setDoc(userDocRef, {
          guestbook: [{ name, message: newMessage }],
        });
      }

      setGuestbook(updatedUserDoc.exists() ? updatedUserDoc.data().guestbook || [] : []);

      setNewMessage('');
      setName('');
    } catch (error) {
      console.error('Error adding message:', error.message);
    }
  };

  return (
    <div className="product-list-container">
      <div className="product-list-container">
        <h3>{title}</h3>
      </div><br></br>
      <MusicGallery music={uploads.filter((upload) => upload.type === 'audio')} /><br></br>
      <ImageGallery images={uploads.filter((upload) => upload.type === 'image')} />
      <div className="paragraphs-container">
        {paragraphs.map((paragraph, index) => (
          <p key={index}><b><i>{paragraph}</i></b></p>
        ))}
      </div>
      <div className="video-gallery-container">
        <VideoGallery videos={uploads.filter((upload) => upload.type === 'video')} />
      </div>
      <div className="product-list-container">
        <Guestbook guestbook={guestbook} />
        <div className="leave-message-container">
          <h3>Leave a Message</h3>
          <form>
            <label>
              Name:
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </label>
            <br />
            <label>
              Message:
              <textarea value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
            </label>
            <br />
            <button type="button" onClick={handleAddMessage}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PublicUserProfile;