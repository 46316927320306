import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Database from './Components/Database.js';
import LandingPage from './pages/LandingPage.js';
import Login from './Components/Login';
import Profile from './Components/Profile';
import Signup from './Components/Signup.js';
import { initializeApp } from 'firebase/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './Components/ForgotPassword.js';
import InfoPage from './pages/info.js';
import UserDashboard from './UserDashboard.js';
import PublicUserProfile from './PublicUserProfile';
import PrivacyPolicy from './pages/privacypolicy.js';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyD8sy9sKR1P8gn9zGJDYlnIM0kUeNmrYcA",
    authDomain: "memoircode.firebaseapp.com",
    projectId: "memoircode",
    storageBucket: "memoircode.appspot.com",
    messagingSenderId: "335848966624",
    appId: "1:335848966624:web:e2f435a804d80feb5b0385",
    measurementId: "G-LLX6RVFFSD"
  };


  initializeApp(firebaseConfig);


  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src="/newlogo.png" alt="Memoir" />
        </header>

        <main>
      
          <ToastContainer />

          <Routes>
            <Route path="/Database" element={<Database />} />
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
           
            <Route path="/database/:userId" element={<PublicUserProfile />} />

            {/* User Dashboard */}
            <Route path="/dashboard" element={<UserDashboard />} />

            
          </Routes>
        </main>
        
        <footer>
          <div><b>Info@MemoirCodes.life</b></div>
        </footer>
      </div>
    </Router>
  );
}

export default App;